/* Font Faces */
@font-face {
  font-family: 'Fakt-Normal';
  src: url('/assets/fonts/Fakt/Fakt-Normal.woff') format('woff');
}
@font-face {
  font-family: 'Fakt-Bold';
  src: url('/assets/fonts/Fakt/Fakt-Bold.woff') format('woff');
}
@font-face {
  font-family: 'PitchSans-Medium';
  src: url('/assets/fonts/PitchSans/OTF/PitchSans-Medium.otf') format('opentype'),
    url('/assets/fonts/PitchSans/TTF/PitchSans-Medium.ttf') format('truetype'),
    url('/assets/fonts/PitchSans/WOFF2/pitch-sans-medium.woff2') format('woff');
}
@font-face {
  font-family: 'PitchSans-Bold';
  src: url('/assets/fonts/PitchSans/OTF/PitchSans-Bold.otf') format('opentype'),
    url('/assets/fonts/PitchSans/TTF/PitchSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/PitchSans/WOFF2/pitch-sans-bold.woff2') format('woff');
}
a {
  text-decoration: underline;
  color: var(--color-content-link);

  &:link {
    color: var(--color-content-link);
  }
  &:visited {
    color: var(--color-content-link-visited);
  }
  &:hover {
    color: var(--color-content-link-hover);
  }
  &:active {
    color: var(--color-content-link-active);
  }
}
strong {
  font-weight: bold;
  &[color] {
    font-weight: normal;
    color: var(--pink-core);
  }
}

/* Typographic mixin styles */
/* ================================================================== */

/*
    BODY TEXT
    used in paragraphs, snippets
*/
@mixin body-text {
  font-family: 'Fakt-Normal', sans-serif;
  line-height: 1.4;
}
body {
  @include body-text;
}

/* Body text sizes */
@mixin xl-body {
  @include body-text;
  font-size: 1.5rem;
}
@mixin l-body {
  @include body-text;
  font-size: 1.25rem;
}
@mixin m-body {
  @include body-text;
  font-size: 1rem;
}
@mixin s-body {
  @include body-text;
  font-size: 0.8125rem;
}

/*
    HEADINGS
*/

@mixin heading {
  font-family: 'PitchSans-Medium', Helvetica, sans-serif;
  line-height: 1.2;
  letter-spacing: -0.03em;
}

/* Head sizes */
@mixin xxl-head {
  @include heading;
  font-size: 5rem;
}
@mixin xl-head {
  @include heading;
  font-size: 3.5rem;
}
@mixin l-head {
  @include heading;
  font-size: 3rem;
}
@mixin m-head {
  @include heading;
  font-size: 2.25rem;
}
@mixin s-head {
  @include heading;
  font-size: 1.875rem;
}
@mixin xs-head {
  @include heading;
  font-size: 1.5rem;
}
@mixin xxs-head {
  @include heading;
  font-size: 1.3rem;
}

/*
    SUBHEADS
*/

@mixin subhead {
  font-family: 'PitchSans-Bold', Helvetica, sans-serif;
  line-height: 1.2;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

/* Subhead sizes */
@mixin xxl-subhead {
  @include subhead;
  font-size: 3rem;
}
@mixin xl-subhead {
  @include subhead;
  font-size: 2.25rem;
}
@mixin l-subhead {
  @include subhead;
  font-size: 1.875rem;
}
@mixin m-subhead {
  @include subhead;
  font-size: 1.5rem;
}
@mixin s-subhead {
  @include subhead;
  font-size: 1rem;
}
@mixin xs-subhead {
  @include subhead;
  font-size: 0.8125rem;
}

/*
    AUX TEXT
    currently only used in buttons
*/

@mixin aux-text {
  font-family: 'PitchSans-Bold', Helvetica, sans-serif;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

/* Aux text sizes */
@mixin l-aux {
  @include aux-text;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@mixin m-aux {
  @include aux-text;
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin s-aux {
  @include aux-text;
  font-size: 0.8125rem;
  line-height: 1rem;
}

/**
* contentful blog styles starts
*/

.contentful-blog-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'PitchSans-Bold';
  }

  h1 {
    @include xl-head;
  }
  h2 {
    @include l-head;
  }
  h3 {
    @include m-head;
  }
  h4 {
    @include s-head;
  }
  h5 {
    @include xs-head;
  }
  h6 {
    @include xxs-head;
  }
  hr {
    background-color: #d1d1d1;
    height: 1px;
  }
  table {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    th {
      background-color: #285652;
      @include xs-head;
      font-weight: 500;
      color: #fff;
      padding: 8px;
    }
    td {
      @include m-body;
      padding: 10px 8px;
    }
    tr {
      &:nth-child(even) {
        background-color: #e6e6e6;
      }
      &:nth-child(odd) {
        background-color: #fbfbfb;
      }
    }
    th,
    td {
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
      p {
        margin: 0;
        text-align: center;
      }
    }
  }

  ul,
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li {
    @include xs-head;
  }
  ol {
    li {
      list-style: decimal;
    }
  }
  ul {
    li {
      &::marker {
        color: #ff6289;
      }
      list-style: disc;
    }
  }

  blockquote {
    background: #285652;
    padding: 80px;
    border-radius: 10px;
    width: 90%;
    margin: 14px auto;
    position: relative;
    p {
      margin: 0;
      color: #fff;
    }
    &:before {
      content: '\201F';
      font-size: 9.375rem;
      font-family: 'Pitch Sans';
      font-weight: 400;
      transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);
      position: absolute;
      top: 10px;
      line-height: 0;
      left: 15px;
      color: #f9f9f9;
    }
  }
  img {
    max-width: 100%;
    display: block;
    max-width: 570px;
    max-height: 500px;
    border-radius: 17px;
    margin: 20px 0;
  }
}

//  Contentful blog style responsive
@media (max-width: 786px) {
  .contentful-blog-content {
    h1 {
      font-size: 3rem !important;
    }
    h2 {
      font-size: 2.25rem !important;
    }
    h3 {
      font-size: 1.875rem !important;
    }
    h4 {
      font-size: 1.5rem !important;
    }
    h5,
    li,
    th {
      font-size: 1.3rem !important;
    }
  }
}

@media (max-width: 500px) {
  .contentful-blog-content {
    h1 {
      font-size: 2.25rem !important;
    }
    h2 {
      font-size: 1.8rem !important;
    }
    h3 {
      font-size: 1.5rem !important;
    }
    h4 {
      font-size: 1.3rem !important;
    }
    h5,
    li {
      font-size: 1.1rem !important;
    }
    th {
      font-size: 1.1rem !important;
      font-weight: 600 !important;
    }
    blockquote {
      padding: 80px 40px !important;
      width: auto !important;
      border-radius: 10px !important;
    }
  }
}

/**
* contentful blog styles ends
*/
