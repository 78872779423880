/* Responsive Breakpoints */
/* ================================================================== */
@mixin devices($breakpoint) {
  @if $breakpoint == small-mobile {
    @media only screen and (min-width: 200px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media only screen and (min-width: 481px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == mid-tablet {
    @media only screen and (min-width: 768px) and (max-width: 1025px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  }
  @if $breakpoint == wide-desktop {
    @media only screen and (min-width: 1440px) {
      @content;
    }
  }
  @if $breakpoint == extra-wide-desktop {
    @media only screen and (min-width: 2600px) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin content-card {
  background: var(--white);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 8%);
  border-radius: $content-border-radius;
  padding: 8px;

  @include devices(mobile) {
    padding: 0 12px;
  }
}

@mixin max-width-centered-content {
  max-width: 1500px;
  margin: 0 auto;
}

@mixin max-width-centered-content-smaller {
  max-width: 900px;
  margin: 0 auto;
}
