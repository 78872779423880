@import './typography.scss';
@import '../assets/styles/common.scss';

/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

/* Body styling */
/* ==================================================== */
html {
  height: auto;
}
body {
  transform: none;
  overflow-x: hidden;
  overflow-y: inherit;
  position: inherit;
  height: auto;
  width: auto;
  color: var(--color-content-primary);
  max-width: 2600px;
  margin: 0 auto;
}
body.menu-open {
  overflow-y: hidden;
}

/* Base button styling */
/* ==================================================== */
ion-button {
  --background: var(--white);
  --color: var(--black);
  --border-radius: 100px;
  --padding-top: 16px;
  --padding-end: 24px;
  --padding-bottom: 16px;
  --padding-start: 24px;
  --box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  --transition: background-color var(--transition-speed-hover), color var(--transition-speed-hover);
  height: inherit;
  @include s-aux;
  margin: 0;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 50px;
  &:hover {
    transform: scale(1.03);
    color: var(--pink-core);
    --background: var(--white);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    --cursor: pointer;
  }

  &::part(native) {
    line-height: inherit;
  }
}

/* Button sizing and styling variations */
/* ==================================================== */
ion-button.small {
  --padding-top: 8px;
  --padding-end: 16px;
  --padding-bottom: 8px;
  --padding-start: 16px;
}
ion-button.large {
  --padding-top: 22px;
  --padding-end: 32px;
  --padding-bottom: 22px;
  --padding-start: 32px;
  @include m-aux;
}
ion-button.high-contrast {
  --background: var(--black);
  --color: var(--white);
}
ion-button.medium-contrast {
  --background: var(--pink-core);
}
ion-button:hover .icon-inner {
  --background: var(--white);
}
ion-button.outline {
  --background: transparent;
  --border-color: var(--yellow-250);
  --border-style: solid;
  --border-width: 2px;
  --color: var(--black);

  &:hover,
  &:focus {
    --background: var(--yellow-50) !important;
    transform: scale(1.02);
    color: var(--black) !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }
}
ion-button.outline-secondary {
  --background: transparent;
  --border-color: var(--white);
  --border-style: solid;
  --border-width: 2px;
  --color: var(--white);

  &:hover,
  &:focus {
    --border-color: var(--white) !important;
    --background: #fff !important;
    color: var(--black) !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    transform: scale(1.02);
  }
}
ion-button.clear {
  --background: var(--white);
}
ion-button.block {
  display: block;
}

/* Specialty buttons */
/* Context specific buttons are defined in their component SCSS files */
/* ==================================================== */
@keyframes animateContinueArrow {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  25% {
    opacity: 0;
    transform: translateX(10px);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px);
  }
  55% {
    opacity: 1;
    transform: translateX(0px);
  }
}
ion-button.continue,
ion-button.contained {
  --padding-top: 8px;
  --padding-end: 8px;
  --padding-bottom: 8px;
  --padding-start: 32px;
  border: 2px solid var(--gray-250);
  --background: var(--white);
  @include m-aux;
}
ion-button.continue .btn-icon,
ion-button.contained .btn-icon {
  padding: 14px 14px 12px 14px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--pink-550);
}
ion-button.continue.block .btn-icon {
  margin-left: auto;
}
ion-button.continue.medium-contrast .btn-icon {
  --background: var(--white);
}
ion-button.continue:hover {
  color: var(--black);
}
ion-button.continue:hover ion-icon {
  animation-name: animateContinueArrow;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-timing-function: linear;
}

ion-button.continue {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 50px;

  &:hover,
  &:focus {
    --background: var(--pink-550) !important;
    border-color: var(--pink-550);
    transform: scale(1.02);
    color: var(--black) !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  div {
    ion-icon {
      color: var(--black);
    }
  }
}
ion-button.menu-button {
  border-width: 2px !important;

  &:hover,
  &:focus {
    border-color: var(--white) !important;
  }
}
ion-button.contained {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 50px;

  &:hover,
  &:focus {
    border: 2px solid var(--pink-550);
    --background: var(--pink-550) !important;
    transform: scale(1.02);
    color: var(--black) !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  div {
    ion-icon {
      color: var(--black);
    }
  }
}
ion-button.filled {
  // --background: var(--yellow-250);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 2px solid var(--gray-250);

  &:hover,
  &:focus {
    border-color: var(--pink-550);
    --background: var(--pink-550) !important;
    transform: scale(1.02);
    color: var(--black);
  }
}

ion-modal,
ion-alert,
ion-loading {
  height: 100vh;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}
ion-input.input-faq {
  @include l-body;
}

ion-modal.business-signup-modal {
  --width: calc(100% - 32px);
  --height: 80%;

  &::part(content) {
    border-radius: 16px;
  }
}

ion-modal.contact-sales-modal {
  --width: calc(100% - 32px);
  --height: 582px;

  &::part(content) {
    border-radius: 16px;
  }
}
ion-modal.confirm-modal {
  --width: calc(100% - 32px);
  --height: 236px;

  &::part(content) {
    border-radius: 16px;
  }
}

@include devices(tablet) {
  ion-modal.business-signup-modal {
    --width: auto;
    --min-width: 750px;
    --height: 500px;
  }

  ion-modal.contact-sales-modal {
    --width: 438px;
    --height: 582px;
  }
  ion-modal.confirm-modal {
    --width: 438px;
    --height: 236px;
  }
}
