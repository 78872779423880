$content-border-radius: 8px;
$panel-border-radius: 16px;

// feel free to change to whatever you prefer this should be
$panel-box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.1);
$panel-box-shadow-inset: inset 0 0 18px 2px rgba(0, 0, 0, 0.15);
$panel-desktop-width: calc(100% - 32px);
$panel-height: 600px;
$panel-desktop-height: 650px;
$panel-max-desktop-width: 1500px; // temp number till we decide what it should be
$panel-mobile-border-radius: 16px;
