/*
    Color Tokens

    * Use these semantic color variables
    only as directed by Figma design.

    * Use them semantically (i.e. don't use
    "background" colors for text.

    * These colors change based on light vs
    dark theme. Use these especially when transition
    between light and dark modes are needed.
*/

:root {
  --color-background-primary: var(--white);
  --color-background-alt: var(--gray-50);

  --color-content-primary: var(--black);
  --color-content-alt: var(--gray-800);
  --color-content-low-contrast: var(--gray-600);

  --color-content-inverse: var(--white);
  --color-content-inverse-alt: var(--gray-400);

  --color-content-link: var(--blue-750);
  --color-content-link-hover: var(--blue-800);
  --color-content-link-active: var(--purple-800);
  --color-content-link-visited: var(--purple-750);

  --color-border-primary: var(--gray-600);
  --color-border-alt: rgba(144, 144, 144, 0.48);
  --color-border-selected: var(--purple-core);
}

body.dark {
  --color-background-primary: var(--gray-950);
  --color-background-alt: var(--gray-850);

  --color-content-primary: var(--white);
  --color-content-alt: var(--gray-400);
  --color-content-low-contrast: var(--gray-750);

  --color-content-inverse: var(--black);
  --color-content-inverse-alt: var(--gray-800);

  --color-content-link: var(--blue-600);
  --color-content-link-hover: var(--white);
  --color-content-link-active: var(--blue-200);
  --color-content-link-visited: var(--purple-600);

  --color-border-primary: var(--gray-750);
  --color-border-alt: rgba(98, 98, 98, 0.48);
  --color-border-selected: var(--purple-core);
}

/*
    Other Tokens
*/

:root {
  --transition-speed-hover: 150ms;
}
