@use '../../theme/tokens.scss';
@use '../../theme/colors.scss';
@import './mixins.scss';
@import './variables.scss';
@import '../../theme/typography.scss';

ul,
ol {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
dl,
dt,
dd {
  margin: 0;
}
.contained-content {
  @include max-width-centered-content;
  padding: 1rem;
}

.contained-content-no-padding {
  @include max-width-centered-content;
}

.contained-content-smaller {
  @include max-width-centered-content-smaller;
}

.interior-page-content {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem 1.5rem;
  @include m-body;
  color: var(--color-content-alt);

  h1 {
    margin: 0 0 3rem;
    @include m-head;
    color: var(--color-content-primary);
  }

  > img {
    display: block;
    margin: 0 auto 3rem;
  }

  p {
    margin: 0 auto 1rem;
  }

  b {
    font-weight: bold;
  }

  h2 {
    display: block;
    margin: 0;
    padding: 2rem 0 1.5rem;
    @include s-subhead;
    color: var(--color-content-primary);
  }

  h3 {
    display: block;
    margin: 0;
    padding: 1.5rem 0 0.75rem;
    @include xs-subhead;
    color: var(--color-content-primary);
  }

  li {
    margin-left: 1.5rem;
  }

  ul li {
    list-style: disc outside;
  }

  ol li {
    list-style: numbered outside;
  }

  @include devices(tablet) {
    padding: 5rem 2rem 3rem;
    @include xl-body;

    h1 {
      margin: 0 0 3rem;
      @include xl-head;
      color: var(--color-content-primary);
    }

    h2 {
      margin: 0;
      @include m-subhead;
      color: var(--color-content-primary);
    }

    h3 {
      margin: 0;
      @include s-subhead;
      color: var(--color-content-primary);
    }

    p {
      margin: 0 auto 1.5rem;
    }
  }
}
.headlines {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  div {
    @include content-card;
    @include m-head;

    @include devices(mobile) {
      @include l-head;
    }
    @include devices(tablet) {
      @include xl-head;
    }
    @include devices(desktop) {
      @include xxl-head;
    }

    &:nth-child(1) {
      z-index: 2;
    }
    &:nth-child(2) {
      z-index: 1;
    }
  }
}
