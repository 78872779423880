@use './assets/styles/normalize.scss';
@use './assets/styles/accessibility.scss';
@use './assets/styles/common.scss';
// @import '~ng-zorro-antd/ng-zorro-antd.min.css';

/* Styles for Contentful WYSIWYG content */
/* These need to be loaded outside of component CSS for reasons undefined */

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.accordion-container .accordion-panel,
.open-positions-container .panel,
.post {
  ul {
    padding-left: 1rem;

    li {
      list-style: disc outside;
    }
  }

  ol {
    padding-left: 1rem;

    li {
      list-style: decimal outside;
    }
  }
}

body.modal-open {
  overflow: hidden !important;
}

.faq-section {
  padding: 0.531rem 0;
}

.faq-section:first-of-type {
  padding-top: 0;
  padding-bottom: 0.531rem;
}

.faq-section:last-of-type {
  padding-top: 0.531rem;
  padding-bottom: 0;
}

input[type='text']::placeholder {
  @media (max-width: 475px) {
    font-size: 22px;
  }

  @media (max-width: 375px) {
    font-size: 16px;
  }
}

/* End Contentful Styles */
